<template>
  <v-container class="py-7">
    <div class="heading mb-7">История заказов</div>

    <template v-if="list && list.length">
      <div v-for="(item, i) in list" :key="i" class="mb-4">
        <v-sheet
          :key="i"
          class="custom-field mb-2"
          v-ripple="{ class: 'secondary--text' }"
          @click="
            $router.push({
              name: 'HistoryDetail',
              params: { id: item.order_id },
            })
          "
        >
          <span>Заказ №{{ item.order_id }}</span>
          <v-chip :color="getStatusColor(item.status_raw)" small dark class="ml-3">{{
            item.status
          }}</v-chip>
          <v-icon color="secondary" size="32" class="ml-auto mr-n2">{{
            icons.mdiDotsVertical
          }}</v-icon>
        </v-sheet>
      </div>
    </template>

    <div v-else>Вы не совершали заказов</div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { mdiDotsVertical } from "@mdi/js";

export default {
  name: "History",

  data: () => ({
    icons: {
      mdiDotsVertical,
    },
    active: null,
  }),

  computed: {
    ...mapState("user", { list: "history" }),
  },

  methods: {
    getStatusColor(status) {
      switch (status) {
        case "Отменен":
          return "#EF4444";
        case "Доставлен":
          return "#34D399";
        case "paid":
          return "#F472B6";
        case "Новый":
          return "rgb(255, 118, 51)";
        case "Принят":
          return "rgb(201, 172, 94)";
        case "На кухне":
          return "rgb(193, 146, 202)";
        case "В процессе":
          return "rgb(1, 179, 188)";
        case "Передан курьеру":
          return "rgb(1, 178, 152)";
        case "Готов к выдаче":
          return "#818CF8";
        default:
          return "#94D163";
      }
    },
  },

  async created() {
    const { commit, dispatch } = this.$store;
    commit("overlay/update", { active: true });
    await dispatch("user/loadHistory");
    commit("overlay/update", { active: false });
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
</style>
